<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Update Certificate
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        ref="form"
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>

          <b-col md="3">
            <b-form-group
              label=" First Name of Certified
"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="City Name"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="addCourseForm.first_name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label=" Middle Name of Certified
"
              label-for="blog-edit-slug"
              class="mb-2"
            >

              <validation-provider
                #default="{ errors }"
                name="In-Link Name"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addCourseForm.middle_name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Last Name of Certified
"

              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="In-Slider Order"
                rules="required"
              >
                <b-form-input
                  id="video_link"
                  v-model="addCourseForm.last_name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Unique Number of Certified
"

              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="In-Slider Order"
                rules="required"
              >
                <b-form-input
                  id="video_link"
                  v-model="addCourseForm.ID_certificate"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Course Type"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.course_type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="label"
                  :options="CourseType"
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="addCourseForm.course_type != 'Dedicated' && addCourseForm.course_type!=='Custom'"
            md="4"
          >
            <b-form-group
              label="Category"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.category_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="type"
                  :options="categoriesList"
                  :reduce="(val) => val.id"
                  @input="getCoursesByCategory(addCourseForm.category_id)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
           
            md="4"
          >
            <b-form-group
              label="Course"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Course"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.course_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="coursesList"
                  :reduce="(val) => val.id"
                >
                  <template #no-options="{ search, searching, loading }">
                    No data ...
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="addCourseForm.course_type=='Custom'"
            md="3"
          >
            <b-form-group
              label="Course Custom name
"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="City Name"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="addCourseForm.course_custom_name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Certificate/Image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE2"
                      :src="Tabimage"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refPreviewE2.src = Tabimage

                            addCourseForm.tab_image=''
                    "
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block  mt-1">
                    <b-form-group
                      label="Tab Image "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputE2"
                          v-model="addCourseForm.tab_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                  </div>
                </b-media-body>
              </b-media>
            </div>
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Certificate PDF
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >

                <b-media-body>

                  <b-card-text class="my-50" />
                  <div class="d-inline-block">
                    <b-form-group
                      label="Certificate PDF "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Icon Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputEl"
                          v-model="addCourseForm.certificate_pdf"
                          accept=".pdf"
                          placeholder="Choose file"
                          @input="inputImageRenderer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive, computed } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import Vue from 'vue'
import store from '@/store'

export default {

  setup() {
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)

    const Tabimage = ref('media/svg/files/blank-image.svg')
    const { route } = useRouter()
    const { id } = route.value.params
    //   const productId = productSlug.substring(id.lastIndexOf('-') + 1)
    const categoriesList = ref([])
    const coursesList = ref([])
    const CategoryForm = computed(() => store.state.blog.certificate)
    const addCourseForm = reactive(CategoryForm)
    const getCoursesByCategory = id => {
      addCourseForm.course_id = ''
      let online = null
      if (addCourseForm.course_type == 'Classic') {
        online = 0
      } else {
        online = 1
      }
      store
        .dispatch('courses/getAllCoursesList', {
          'filter[category_id]': id,
          'filter[online]': online,
        })
        .then(response => {
          coursesList.value = response.data
        })
    }
    store.dispatch('categories/AllCategory')
      .then(response => {
        categoriesList.value = response.data.data
      })

    store.dispatch('blog/GetCertificate', { id })
      .then(response => {
        store.commit('blog/GET_CERTIFICATE', response?.data.data)
      })
    const CourseType = ref([{
      label: 'Classic',
      id: 'Classic',
    },
    {
      label: 'Dedicated',
      id: 'Dedicated',
    },
    {
      label: 'Online',
      id: 'Online',
    },
    {
      label: 'Custom',
      id: 'Custom',
    }])
   

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const addCourseFormalidate = ref()

    const save = () => {
      const formData = new FormData()
      formData.append('link_id', addCourseForm.value.link_id)

      formData.append('position', addCourseForm.value.position)
      // formData.append("link_id", addCourseForm.value.link_id);
      formData.append('type', addCourseForm.value.type)
      formData.append('tab_image', addCourseForm.value.tab_image)
      formData.append('icon_image', addCourseForm.value.icon_image)
      formData.append('alt_tab_image', addCourseForm.value.alt_tab_image)
      formData.append('alt_icon_image', addCourseForm.value.alt_icon_image)
      formData.append('_method', 'put')
      addCourseFormalidate.value.validate().then(success => {
        if (success) {
          store.dispatch('blog/UpdateaCertificate', { id, formData })
            .then(response => {
              Vue.swal({
                icon: 'success',
                title: 'Updated!',

                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        }
      })
    }
    return {
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormalidate,
      id,
      CategoryForm,
      categoriesList,
      coursesList,
      getCoursesByCategory,
      CourseType,

      save,
      required,
      email,
    }
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
